import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityService } from '../Services/utility.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {

  loading = false;
  data: any = null;
  email: string = "";
  lastName: string = "";
  selectedOrder: any = null;
  showMembership: boolean = true;
  memberNumber: string = null;
  membershipId: string = null;
  memberEmail: string = null;
  isMember = false;
  showEmailForm = true;
  emailNotFound = false;
  notFoundMessage = null;
  items = null;
  membershipOptions = null;
  membershipCategory = null;
  numberNotFound = false;

  memberMessage = null;
  memberTemproraryMessage = null;
  constructor(public http: HttpClient, public utilityService: UtilityService, private location: LocationStrategy, private $gaService: GoogleAnalyticsService) {

    //history.pushState(null, null, window.location.href);
    this.location.onPopState((state) => {
      if (this.showEmailForm || this.items) {
        this.reset();
      } else {
        return true;
      }
    });

  }

  ngOnInit() {
    this.utilityService.getMessage("Standard", "Membership", (message) => {
      if (message && message.data) {
        this.memberMessage = this.utilityService.getUnSanatizedMessage(message.data);
      }else{
        this.memberMessage = "Thank you for your Membership support!";
      }
    },
      (error) => {
        console.log(error);
        console.log("Error getting: Standard/Membership")
      });

    //this.utilityService.loadFeshdeskWidget(67000002747, true);

    this.utilityService.getMessage("Temprorary", "Membership", (message) => {
      if (message && message.data) {
        this.memberTemproraryMessage = this.utilityService.getUnSanatizedMessage(message.data);
      }else{
        this.memberTemproraryMessage = `
        <div>Thank you for your support</div>
        `;
      }
    },
      (error) => {
        console.log(error);
        console.log("Error getting: Temprorary/Membership")
      });

    this.utilityService.getMessage("Standard", "Membership Not Found", (message) => {
      if (message && message.data) {
        this.notFoundMessage = this.utilityService.getUnSanatizedMessage(message.data);
      }
    },
      (error) => {
        console.log(error);
        console.log("Error getting: Standard/Membership Not Found")
      });
  }

  GTMEvent(event: string, data: string) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: event,
      data: data,
    };
    //this.gtmService.pushTag(gtmTag);
  }

  showRenewal(date) {
    let numDate = Date.parse(date);
    //let selectedTimestamp = new Date(numDate);
    //let today = new Date();
    //let today = new Date().getTime();
    var timestamp = new Date().getTime() + (90 * 24 * 60 * 60 * 1000)
    if (timestamp > numDate) {
      // The selected time is less than 30 days from now
      let a = document.getElementById('renewalBtn');
      if (a) {
        a.addEventListener('click', () => {
          this.$gaService.event('membership_lookup','membership','Renew Membership');
        })
      }
      return true;
    }
    return false;
  }

  reset() {
    this.data = null;
    this.email = "";
    this.lastName = "";
    this.selectedOrder = null;
    this.showMembership = true;
    this.memberNumber = null;
    this.memberEmail = null;
    this.membershipId = null;
    this.isMember = false;
    this.showEmailForm = true;
    this.emailNotFound = false;
    this.items = null;
    this.membershipOptions = null;
    this.membershipCategory = null;
    this.numberNotFound = false;
    this.$gaService.event('membership_lookup','membership','New Lookup');
  }

  validateMembershipNumber(form) {
    this.loading = true;
    this.$gaService.event('membership_lookup','membership','Lookup By Member Code');
    this.http.get("./api/membership/ValMemberBarCode?barcode=" + this.memberNumber + "&lastname=" + this.lastName, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    }).subscribe(response => {

      let membershipStatus = (<any>response).data;

      if (membershipStatus != 'not found') {
        this.items = membershipStatus.list;
        history.pushState(null, null, window.location.href);
        this.showMembership = false;
        this.getMembershipCategory();
        this.$gaService.pageView('/Membership', 'Membership By Member Code', undefined, {
          user_id: this.memberNumber
        });
      } else {
        this.$gaService.event('membership_lookup_not_found','membership','Lookup By Member Code');
        this.showEmailForm = true;
        this.showMembership = true;
        this.numberNotFound = true;
        this.loading = false;
      }
      console.log(this.items);
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't verify our membership at this moment");
      }
    );
  }

  isExpired(date: string) {
    let numDate = Date.parse(date);
    let inputDate = new Date(numDate);
    let today = new Date();

    if (inputDate < today) {
      return true
    }
    return false;
  }
  validateMembershipEmail(form) {
    this.loading = true;
    this.$gaService.event('membership_lookup','membership','Lookup By Email');
    this.http.get("./api/membership/ValMemberEmail?email=" + this.memberEmail + "&lastname=" + this.lastName, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      let membershipStatus = (<any>response).data;

      if (membershipStatus != 'not found') {
        this.items = membershipStatus.list;
        this.showMembership = false;
        this.getMembershipCategory();
        this.$gaService.pageView('/Membership', 'Membership By Email', undefined, {
          user_id: this.memberNumber
        });
      } else {
        this.emailNotFound = true;
        this.showMembership = false;
        this.items = [];
        this.loading = false;
        this.$gaService.event('membership_lookup_not_found','membership','Lookup By Email');
      }
      console.log(this.items);
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't verify our membership at this moment");
      }
    );
  }

  getMembershipOptions() {
    this.loading = true;
    this.http.get("./api/membership/GetMembershipOptions?membershipid=" + this.items[0].membershipId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.membershipOptions = (<any>response).data;
      console.log(this.membershipOptions);
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't verify our membership at this moment");
      }
    );
  }

  getMembershipCategory() {
    this.loading = true;
    this.http.get("./api/membership/GetMembershipCategory?id=" + this.items[0].categoryId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.membershipCategory = (<any>response).data;
      console.log(this.membershipCategory);
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't verify our membership at this moment");
      }
    );
  }

}
