import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityService } from '../Services/utility.service';

@Component({
  selector: 'app-rebook',
  templateUrl: './rebook.component.html',
  styleUrls: ['./rebook.component.css']
})
export class RebookComponent implements OnInit {

  loading = false;
  data: any = null;
  order: any = null;
  orderDetails: any = null;
  email: string = "";
  lastName: string = "";
  selectedOrder: any = null;
  rebook: boolean = false;
  selectedDate: any = null;
  selectedTime: any = null;
  calendar: any = null;
  minDate: any = null;
  maxDate: any = null;
  event: any = null;
  times: any = null;
  timeerror: any = null;
  membershipId: string = null;
  showConfirm: boolean = false;
  tempRebookObj: any = null;
  someItemNotFound = false;
  feeObj: any = null;
  groups = [];
  groupedEvents = [];
  fees: any = null;
  canRebookTimeFlag: boolean = false;
  showPayment: boolean = false;
  showSuccess: boolean = false;

  rebookItemsForPayment = [];


  orderWithPayment = {
    "email": "",
    "orderId": "",
    "notes": "",
    "rebookItems": [],
    "incidentReasonCode": "Rebook Event",
    "eventRebookFeeCounts": [],
    "noEmail": false,
    "sendEmailToAll": true,
    payment: null,
    "billingAddress1": "",
    "billingAddress2": "",
    "billingCity": "",
    "billingState": "",
    "billingCountry": "United States",
    "billingZipCode": ""
  }


  payment = {
    "type": "CreditCard",
    "acmeToken": "",
    "manualEntryCardNumber": "",
    "expDate": "",
    "ccLastFourDigits": "",
    "cvc": "",
    "contactEmail": "",
    "contactFirstName": "",
    "contactLastName": "",
    "phoneNumber": "",
    "address": {
      "country": "United States",
      "streetAddress1": "",
      "streetAddress2": "",
      "city": "",
      "state": "",
      "zipCode": "",
      "otherState": "",
      "otherCountry": "",
      "type": "secondary",
      "isPrimary": false
    },
    "ticketDelivery": "print",
    "firstName": "",
    "lastName": ""
  }





















  constructor(public http: HttpClient, public utilityService: UtilityService, private $gaService: GoogleAnalyticsService, private cdr: ChangeDetectorRef) {
    this.getConfig();
  }

  ngOnInit() {
    //this.$gaService.pageView('/Order', 'Order Lookup');
    //this.email = "100000302";
    //this.checkOrders();
    //this.email = '101774004';
    //this.lastName = 'Gensler';
    /*this.email = '101489905';
    this.lastName = 'Aljishi';*/
    //this.checkOrders();
  }

  reset() {
    this.data = null;
    this.order = null;
    this.orderDetails = null;
    this.email = "";
    this.lastName = "";
    this.rebook = false;
    this.selectedDate = null;
    this.calendar = null;
    this.selectedOrder = null;
    this.selectedTime = null;
    this.times = null;
    this.timeerror = null;
    this.canRebookTimeFlag = false;
    this.fees = null;
    this.feeObj = null;
    this.showPayment = false;
    this.rebookItemsForPayment = [];
    this.tempRebookObj = null;
    this.membershipId = null;
    this.showConfirm = false;
    this.tempRebookObj = null;
    this.someItemNotFound = false;
    this.groups = [];
    this.groupedEvents = [];
    this.showSuccess = false;

    this.orderWithPayment = {
      "email": "",
      "orderId": "",
      "notes": "",
      "rebookItems": [],
      "incidentReasonCode": "Rebook Event",
      "eventRebookFeeCounts": [],
      "noEmail": false,
      "sendEmailToAll": true,
      payment: null,
      "billingAddress1": "",
      "billingAddress2": "",
      "billingCity": "",
      "billingState": "",
      "billingCountry": "United States",
      "billingZipCode": ""
    }

    this. payment = {
      "type": "CreditCard",
      "acmeToken": "",
      "manualEntryCardNumber": "",
      "expDate": "",
      "ccLastFourDigits": "",
      "cvc": "",
      "contactEmail": "",
      "contactFirstName": "",
      "contactLastName": "",
      "phoneNumber": "",
      "address": {
        "country": "United States",
        "streetAddress1": "",
        "streetAddress2": "",
        "city": "",
        "state": "",
        "zipCode": "",
        "otherState": "",
        "otherCountry": "",
        "type": "secondary",
        "isPrimary": false
      },
      "ticketDelivery": "print",
      "firstName": "",
      "lastName": ""
    }



  }

  resend(orderNumber: string, noLoad: boolean = false) {
    if(!noLoad){
      this.loading = true;
    }
    this.http.post("./api/order/resendEmail/" + orderNumber + "?email=" + this.email, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.utilityService.showNotification("success", "Email Sent");
      //this.$gaService.event('resend_email', 'order_lookup', 'Resend Confirmation Email');

    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't send confirmation at this moment");
        //this.$gaService.event('resend_email', 'order_lookup', 'Resend confirmation Email Error');
      }
    );
  }

  getConfig() {

  }

  resetForRebook(eventObj) {
    this.rebook = true;
    this.selectedDate = null;
    this.selectedOrder = eventObj;
    this.selectedTime = null;
    this.times = null;
    this.cdr.detectChanges();
  }

  getCalendar() {
    this.loading = true;
    this.getEventFees();

    console.log(this.selectedOrder);
    let url = "./api/event/cal/" + this.selectedOrder.eventId;  //+ "&lastname=" + this.lastName
    if (this.membershipId != null && this.membershipId.length > 0) {
      url += '?membershipId=' + this.membershipId;
    }

    this.http.get(url
      , {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {
        this.loading = false;
        this.calendar = null;
        this.calendar = (<any>response).data;
        console.log(this.calendar);
        this.setupCalendar();
        this.cdr.detectChanges();
        this.scrollById("calendarSelection");
        //this.$gaService.event('order_lookup','order_lookup',this.lastName);
      },
        err => {
          this.loading = false;
          console.log(err);
          this.utilityService.showNotification("danger", 'could not load event calendar, please try again later')
          //alert('could not load orders, please try again later');
        }
      );
  }

  getEventFees() {
    let url = "./api/order/EventFees/" + this.selectedOrder.eventId;
    this.http.get(url
      , {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {

        this.fees = null;
        this.fees = (<any>response).data;
        console.log(this.fees);
        this.cdr.detectChanges();
        //this.$gaService.event('order_lookup','order_lookup',this.lastName);
      },
        err => {
          console.log('Error loading FEES');
          console.log(err);
          //this.utilityService.showNotification("danger", 'could not load event calendar, please try again later');
          //alert('could not load orders, please try again later');
        }
      );
  }

  removeTimeZone() {
    this.times.times.forEach(item => {
      item.time = item.time.split(',')[0];
    })
  }


  myFilter = (d: Date | null): boolean => {
    let stringDate = d.toISOString().substring(0, 10);
    let found = false;
    if (this.calendar && this.calendar.days) {
      this.calendar.days.forEach(element => {
        if (element.date.includes(stringDate)) {
          found = true;
        }
      });
    }

    return found;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  scrollById(id) {
    try{
    //console.log(`scrolling to ${id}`);
    let el = document.getElementById(id);
    el.scrollIntoView();
    }catch{}
  }


  validate() {
    /*console.log('UDPATING ORDER');
    console.log(this.selectedTime);
    console.log(this.selectedOrder);
    console.log(this.selectedDate);
    console.log(this.event);
    console.log(this.order);
    console.log(this.orderDetails);*/

    //this.selectedOrder.

    //let rebookItems = [];

    let rebookObj = {
      orderId: this.order.id,
      incidentReasonCode: "Rebook Event",
      notes: "From RL App",
      noEmail: false,
      rebookItems: []
    }

    this.selectedOrder.items.forEach(element => {
      let rebookItem = {
        orderItemId: element.orderItemId,
        itemType: element.itemType,
        itemTypeId: element.ticketTypeId,
        rebookQuantity: element.quantity,
        incidentReasonCode: "Rebook Event",
        rebookToEventId: this.selectedTime.id
      };
      //        itemTypeName: element.tick,
      rebookObj.rebookItems.push(rebookItem);
    });

    console.log(rebookObj);

    let pendingObj = {
      eventId: this.selectedOrder.eventId,
      eventDate: cloneDeep(this.selectedDate),
      eventTime: cloneDeep(this.selectedTime.startTime),
      groupDate: cloneDeep(this.selectedOrder.eventDate.substring(0, 10)),
      rebookItems: cloneDeep(rebookObj.rebookItems)
    }
    console.log(pendingObj);
    //var pendingObj = cloneDeep(rebookObj.rebookItems);

    this.utilityService.addToPending(pendingObj);

    //this.makeRebook(rebookObj);
    this.utilityService.showNotification("info", "Changes Pending");
    this.backToCurrentOrderScreen();
    console.log('PEMDING REBOOKS');
    console.log(this.utilityService.pendingRebooks);
  }

  backToCurrentOrderScreen() {
    this.selectedDate = null;
    this.calendar = null;
    this.selectedOrder = null;
    this.selectedTime = null;
    this.rebook = false;
  }

  makeRebook(rebookObj) {

    console.log(rebookObj);
    debugger;
    this.loading = true;
    this.http.post("./api/order/RebookOrder/", rebookObj, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      console.log(response);
      this.loading = false;
      this.utilityService.showNotification("success", "Order Updated");
      this.backToCurrentOrderScreen();
      this.utilityService.pendingRebooks = null;
      this.tempRebookObj = null;
      this.showConfirm = false;
      this.feeObj = null;
      this.showSuccess = true;
      this.checkOrders();
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", err.data);
      }
    );
  }


  applyPendingChanges() {
    let canChange = true;
    let rebookObj = {
      orderId: this.order.id,
      incidentReasonCode: "Rebook Event",
      notes: "From RL App",
      noEmail: false,
      rebookItems: []
    }
    let pendingDate = [];
    this.utilityService.pendingRebooks.forEach(item => {
      pendingDate.push(item.groupDate);
      item.rebookItems.forEach(rItem => {
        rebookObj.rebookItems.push(rItem);
      });
    });
    const dateCounts = {};
    pendingDate.forEach((x) => { dateCounts[x] = (dateCounts[x] || 0) + 1; });
    console.log(dateCounts);

    pendingDate.forEach(x => {
      let i = this.groups.indexOf(x);
      if (i >= 0) {
        console.log(this.groupedEvents[i]);
        if (dateCounts[x] != this.groupedEvents[i].length) canChange = false;
        //this.groupedEvents[i].
      }
    });
    /*const groupCount = {};
    this.groupedEvents.forEach(function (x) { groupCount[x] = (groupCount[x] || 0) + 1; });
    console.log(groupCount);*/
    if (canChange) {
      //this.utilityService.showNotification("Success", "YAY");
      this.makeRebook(rebookObj);
    } else {
      this.utilityService.showNotification("Danger", "Must rebook all events with the Same date");

    }

    /*const dateRebooks = {};
    this.utilityService.pendingRebooks.forEach(function (x) { dateRebooks[x.groupDate] = (dateRebooks[x.groupDate] || 0) + 1; });
    console.log(dateRebooks);*/
    //this.groupedEvents

    //this.makeRebook(rebookObj);

  }


  setupCalendar() {
    if (this.calendar && this.calendar.days) {
      this.calendar.days.forEach(element => {
        this.setMinMaxDate(element.date);
      });
    }
  }

  setMinMaxDate(date) {
    if (this.maxDate) {
      if (this.maxDate < date) this.maxDate = date;
    } else {
      this.maxDate = date;
    }

    if (this.minDate) {
      if (this.minDate > date) this.maxDate = date;
    } else {
      this.minDate = date;
    }

    //console.log('minDate' + this.minDate);
    //console.log('maxDate' + this.maxDate);

  }

  cancelOrder(modal: any) {
    this.loading = true;
    this.http.post("./api/order/cancelOrder/" + this.selectedOrder.orderId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      modal.close();
      this.utilityService.showNotification("success", "Order Cancelled");
      //this.rebook = false;
      this.checkOrders();
      //this.$gaService.event('cancel_order', 'order_lookup', 'Cancel Order');
    },
      err => {
        this.loading = false;
        modal.close();
        console.log(err);
        this.utilityService.showNotification("danger", err.data);
        this.$gaService.event('cancel_order', 'order_lookup', 'Cancel Order Error');
      }
    );
  }

  canCancel(order: any) {
    var canCacel = false;
    order.orderItems.forEach(orderItem => {
      orderItem.lineItems.forEach(item => {
        if (item.orderItemQuantity > 0) {
          canCacel = true;
        }
      });
    });

    return canCacel;
  }

  canRebookEvent() {
    let cancelled = [];
    this.order = this.data.order;
    this.orderDetails = this.data.orderDetails;
    this.orderDetails.eventItems.forEach(orderItem => {
      orderItem.items.forEach(item => {
        if (item.quantity > 0 && item.itemType == "Event") {
          orderItem.canRebook = true;
        } else {
          orderItem.Cancelled = true;
          cancelled.push(orderItem);
        }
      });
    });
    this.rebookedEvent(cancelled);
    this.makeDateGroups();
  }



  checkCharges(rebookObj, validate = false) {
    this.loading = true;
    this.showPayment = false;
    this.tempRebookObj = null;
    this.showConfirm = false;
    this.timeerror = null;
    this.feeObj = null;
    this.http.post("./api/order/checkCharges/", rebookObj, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.feeObj = (<any>response).data;
      console.log(this.feeObj);
      //"{"totalFeeAmount":"0.00","totalRefundAmount":"18.00","totalPaymentAmount":"18.00","subtotalPaymentAmount":"0.00","currentTotal":"18.00","amountPaid":"18.00","currentOrderBalance":"0.00"}"
      /*if (this.feeObj.totalFeeAmount == "0.00" && this.feeObj.totalRefundAmount == this.feeObj.totalPaymentAmount && this.feeObj.currentOrderBalance == "0.00") {
        this.canRebookTimeFlag = true;
      } else {
        this.timeerror = "Tickets on selected date has a different price, please contact the venue";
        this.canRebookTimeFlag = false;
      }*/

      this.canRebookTimeFlag = true;

      /*if(Number.parseFloat(this.feeObj.currentOrderBalance) > 0){
        this.showPayment = true;
      }else{

      }*/
      this.cdr.detectChanges();
      this.scrollById("timeSelection");

      if (validate) {
        this.validatePartial(rebookObj);
        if (Number.parseFloat(this.feeObj.currentOrderBalance) > 0) {
          this.showPayment = true;
        }
      }

      //this.utilityService.showNotification("success", "Charges OK");
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", err.data);
      }
    );
  }

  rebookedEvent(cancelled) {
    /*console.log(cancelled);
    
    cancelled.forEach(acmeEvent => {
      this.orderDetails.eventItems.forEach(orderItem => {
        console.log(orderItem);
        debugger;
        if (orderItem.canRebook && orderItem.eventId == acmeEvent.eventId && !orderItem.Cancelled) {
          orderItem.rebooked = true;
        }
      });
    });*/

    this.orderDetails.eventItems.forEach(orderItem => {
      if (orderItem.canRebook) {
        let rebooked = false;
        this.orderDetails.eventItems.forEach(eventItem => {
          if (eventItem.items.length == orderItem.items.length) {
            let innerRebookCheck = true;
            for (let i = 0; i < orderItem.items.length; i++) {
              if (!orderItem.canRebook && orderItem.items[i].ticketTypeId != eventItem.items[i].ticketTypeId) {
                innerRebookCheck = false;
              }
            }
            if (innerRebookCheck) {
              rebooked = true;
            }
          }
        });

        if (rebooked) {
          orderItem.rebooked = false;
        }
      }
    });
  }

  checkType(type1: string, type2: string): boolean {
    if (type1.toLocaleLowerCase().includes("adult") && type2.toLocaleLowerCase().includes("adult")) return true;

    if (type1.toLocaleLowerCase().includes("child") && type2.toLocaleLowerCase().includes("child")) return true;

    if (type1.toLocaleLowerCase().includes("infant") && type2.toLocaleLowerCase().includes("infant")) return true;

    return false;
  }

  canRebookTime() {
    let priceMatch = true;
    let totaltickets = 0;
    this.canRebookTimeFlag = false;
    this.someItemNotFound = false;
    this.feeObj = null;
    this.showConfirm = false;
    this.timeerror = "Sorry, this order contains a ticket type that is unable to be moved without staff assistance. Please call (630) 725-2066 for assistance.";

    this.selectedOrder.items.forEach(element => {
      let found = false;
      totaltickets += element.quantity;
      console.log(element);
      this.selectedTime.event.priceList.prices.forEach(item => {
        //console.log('PRICE CHECk');
        //console.log(element);
        //console.log(item);
        //console.log(Number.parseFloat(element.discountedUnitPrice));
        //console.log(Number.parseFloat(item.discountedPrice));
        //5ecd88f3221490215aceb127
        //5ecd86bde8827e0ed350812a
        //if(this.selectedTime.)
        if (item.personType.id == element.ticketTypeId || this.checkType(item.personType.name, element.ticketTypeName)) {// && element.discountedUnitPrice == Number.parseFloat(item.discountedPrice)) {
          found = true;
          console.log('FOUND TICKET TYPE');
          console.log(found);
          item.selected = element.quantity;
          item.availableSeats = element.quantity;
          element.toType = item.personType.id;
          element.toTypeName = item.personType.name;
        }
      });

      if (!found) {
        console.log('TICKET TYPE NOT FOUND');
        priceMatch = false;
        this.someItemNotFound = true;
        //this.timeerror = "Tickets on selected date has a different price, please contact the venue";
        //this.timeerror = "Some items might not be available";
        this.timeerror = "Sorry, this order contains a ticket type that is unable to be moved without staff assistance. Please call (630) 725-2066 for assistance.";
        
      }

    });

    this.selectedTime.event.priceList.prices.forEach(item => {
      if (!item.selected) {
        item.selected = 0;
        item.availableSeats = 0;
      }
    });




    if (this.selectedTime.availableSeats < totaltickets) {
      this.timeerror = "Number of tickets to rebook exceeds available tickets for the selected time";
      this.canRebookTimeFlag = false;
    } else {

      if (this.someItemNotFound) {
        this.selectedOrder.items.forEach((element, i) => {
          try {
            this.selectedTime.event.priceList.prices[i].selected = 0;
            this.selectedTime.event.priceList.prices[i].availableSeats = element.quantity;
          } catch {
            console.log('out of range');
          }
        });

      } else {
        let rebookObj = {
          orderId: this.order.id,
          incidentReasonCode: "Rebook Event",
          notes: "From RL App",
          noEmail: false,
          rebookItems: [],
          eventRebookFeeCounts: [
            {
              eventId: this.fees[0].eventId,//eventTemplateId
              count: 0
            }
          ],
        }
        this.selectedOrder.items.forEach(element => {
          let rebookItem = {
            orderItemId: element.orderItemId,
            itemType: element.itemType,
            itemTypeId: element.toType,
            rebookQuantity: element.quantity,
            incidentReasonCode: "Rebook Event",
            rebookToEventId: this.selectedTime.id
          };
          //        itemTypeName: element.tick,
          rebookObj.rebookItems.push(rebookItem);
          rebookObj.eventRebookFeeCounts[0].count += element.quantity;
        });

        this.checkCharges(rebookObj);
      }
    }

    //console.log(totaltickets);
    //console.log(this.selectedTime.availableSeats);


    //this.canRebookTimeFlag = priceMatch && this.selectedTime.availableSeats > totaltickets;

    //return priceMatch && this.selectedTime.availableSeats > totaltickets;
  }

  validateRebookSelection() {
    let totaltickets = 0;
    let totalRebooked = 0;
    let totalTypesToRebook = 0;
    let totalTypesAllowedToRebook = 0;

    let rebookObj = {
      orderId: this.order.id,
      incidentReasonCode: "Rebook Event",
      notes: "From RL App",
      noEmail: false,
      rebookItems: [],
      eventRebookFeeCounts: [
        {
          eventId: this.fees[0].eventId,//eventTemplateId
          count: 0
        }
      ]
    }

    this.selectedOrder.items.forEach(element => {
      totaltickets += element.quantity;
      totalTypesAllowedToRebook++;
      //totalTypesToRebook++;
    });

    console.log(this.selectedTime.event.priceList.prices);
    console.log(this.selectedOrder.items);

    if (this.someItemNotFound) {


      this.selectedTime.event.priceList.prices.forEach(item => {
        //get selected 
        if (item.selected > 0 && totalTypesToRebook < totalTypesAllowedToRebook) {

          this.selectedOrder.items[totalTypesToRebook].forEach(element => {
            let rebookItem = {
              orderItemId: element.orderItemId,
              itemType: item.personType.name,
              itemTypeId: item.personType.id,
              rebookQuantity: item.selected,
              incidentReasonCode: "Rebook Event",
              rebookToEventId: this.selectedTime.id
            }
            totalRebooked += item.selected;
            rebookObj.eventRebookFeeCounts[0].count = totalRebooked;
            rebookObj.rebookItems.push(rebookItem);
          });
          totalTypesToRebook++;
        }
      });

    } else {
      this.selectedTime.event.priceList.prices.forEach(item => {
        //get selected 
        if (item.selected > 0) {
          totalTypesToRebook++;
          this.selectedOrder.items.forEach(element => {
            if (item.personType.id == element.ticketTypeId || this.checkType(item.personType.name, element.ticketTypeName)) {
              let rebookItem = {
                orderItemId: element.orderItemId,
                itemType: element.itemType,
                itemTypeId: element.toType,
                rebookQuantity: item.selected,
                incidentReasonCode: "Rebook Event",
                rebookToEventId: this.selectedTime.id
              }
              totalRebooked += item.selected;
              rebookObj.eventRebookFeeCounts[0].count = totalRebooked;
              rebookObj.rebookItems.push(rebookItem);
            };
          });
        }

      });

    }



    console.log(rebookObj);


    if (this.selectedTime.availableSeats < totalRebooked) {
      this.timeerror = "Number of tickets to rebook exceeds available tickets for the selected time";
      this.canRebookTimeFlag = false;
    } else {
      this.checkCharges(rebookObj, true);
    }
  }









  validatePartial(rebookObj) {
    /*console.log('UDPATING ORDER');
    console.log(this.selectedTime);
    console.log(this.selectedOrder);
    console.log(this.selectedDate);
    console.log(this.event);
    console.log(this.order);
    console.log(this.orderDetails);*/
    this.showConfirm = true;
    this.tempRebookObj = rebookObj;
    //this.makeRebook(rebookObj);
  }


  makeRebookFromRebookObj() {
    this.makeRebook(this.tempRebookObj);
  }


















































  canRebook(datestring) {
    let date = Date.parse(datestring);
    let today = new Date().getTime();
    //return true;
    return today < date;
  }

  makeDateGroups() {
    this.groups = [];
    this.groupedEvents = [];
    this.orderDetails.eventItems.forEach(item => {
      let findItem = this.groups.indexOf(item.eventDate.substring(0, 10));
      if (item.canRebook && findItem < 0) {
        console.log('GROUP ITEM ADDED');
        console.log(item);
        this.groups.push(item.eventDate.substring(0, 10));
        this.groupedEvents.push([item]);
      } else if (item.canRebook && findItem >= 0) {
        console.log('GROUP ITEM ADDED SAME GROUP');
        console.log(item);
        this.groupedEvents[findItem].push(item);
      }
    });


  }

  resetForCalendarClick() {
    this.selectedTime = null;
    this.times = null;
  }

  getEvent() {
    this.cdr.detectChanges();
    console.log('Getting event times');
    console.log(this.selectedDate);
    this.loading = true;

    let url = "./api/event/EventWithTimeByInstanceId/" + this.selectedOrder.eventId + "?date=" + formatDate(this.selectedDate, 'yyyy-MM-dd', 'en-US'); //+ "&lastname=" + this.lastName
    if (this.membershipId != null && this.membershipId.length > 0) {
      url += '&membershipId=' + this.membershipId;
    }

    this.http.get(url
      , {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {
        this.loading = false;
        this.times = (<any>response).data;
        this.removeTimeZone();
        console.log(this.times);
        this.cdr.detectChanges();
        this.scrollById("timeSelection");
        //this.$gaService.event('order_lookup','order_lookup',this.lastName);
      },
        err => {
          this.loading = false;
          console.log(err);
          this.utilityService.showNotification("danger", 'could not load orders, please try again later')
          //alert('could not load orders, please try again later');
        }
      );
  }

  //checkOrders(orderForm: NgForm) {
  checkOrders() {
    this.loading = true;
    this.http.get("./api/order/order/" + this.email + "?lastname=" + this.lastName
      , {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {
        this.loading = false;
        this.data = (<any>response).data;
        this.order = this.data.order;
        this.orderDetails = this.data.orderDetails;
        if (this.orderDetails.membershipId && this.orderDetails.membershipId != "null") {
          this.membershipId = this.orderDetails.membershipId;
          console.log('MEMBERSHIP ID FOUND')
        }
        this.canRebookEvent();
        //console.log(this.data);
        //this.$gaService.event('order_lookup','order_lookup',this.lastName);
      },
        err => {
          this.loading = false;
          console.log(err);
          this.utilityService.showNotification("danger", 'could not load orders, please try again later')
          //alert('could not load orders, please try again later');
        }
      );
  }


  payForRebook() {
    var corspayment = cloneDeep(this.payment);
    var orderPayment = cloneDeep(this.orderWithPayment);

    if (this.order.email && this.order.email.trim() != "") {
      orderPayment.email = this.order.email;
    } else {
      orderPayment.email = this.order.billingEmail;
    }

    orderPayment.orderId = this.order.id;
    orderPayment.notes = this.tempRebookObj.notes;

    orderPayment.rebookItems = this.tempRebookObj.rebookItems;
    orderPayment.eventRebookFeeCounts = this.tempRebookObj.eventRebookFeeCounts;

    //console.log(orderPayment);
    //console.log(corspayment);
    //console.log(this.tempRebookObj);
    corspayment.expDate = corspayment.expDate.replace(/\D/g, '');
    corspayment.manualEntryCardNumber = corspayment.manualEntryCardNumber.replace(/\D/g, '');
    corspayment.address.country = corspayment.billingCountry;
    corspayment.address.city = corspayment.billingCity;
    corspayment.address.state = corspayment.billingState;
    corspayment.address.zipCode = corspayment.billingZipCode;
    corspayment.address.streetAddress1 = corspayment.billingAddress1;
    corspayment.address.streetAddress2 = corspayment.billingAddress2;
    if (this.order.billingEmail && this.order.billingEmail.trim() != "") {
      corspayment.contactEmail = this.order.billingEmail;
    } else {
      corspayment.contactEmail = this.order.email;
    }

    corspayment.acmeToken = corspayment.manualEntryCardNumber.substring(0, corspayment.manualEntryCardNumber.length - 4);
    corspayment.ccLastFourDigits = corspayment.manualEntryCardNumber.substring(corspayment.manualEntryCardNumber.length - 4, corspayment.manualEntryCardNumber.length);
    orderPayment.payment = corspayment;

    //console.log(orderPayment);
    this.makeRebookWithPayment(orderPayment);
  }



  makeRebookWithPayment(payment) {
    this.loading = true;
    this.http.post("./api/order/MakePayment/", payment, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      //console.log(response);
      this.loading = false;
      this.utilityService.showNotification("success", "Order Updated");
      this.backToCurrentOrderScreen();
      this.utilityService.pendingRebooks = null;
      this.tempRebookObj = null;
      this.showConfirm = false;
      this.feeObj = null;
      this.showSuccess = true;
      //this.resend(this.order.orderNumber, true);
      this.checkOrders();
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", err.data);
      }
    );
  }
}
