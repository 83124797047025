import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../Services/utility.service';

@Component({
  selector: 'app-check-api',
  templateUrl: './check-api.component.html',
  styleUrls: ['./check-api.component.css']
})
export class CheckApiComponent implements OnInit {

  loading = false;
  api:any = null;
  constructor(public http: HttpClient, public utilityService: UtilityService) {
  }

  ngOnInit() {
    this.check();
  }

  check() {
    this.loading = true;
    this.http.get("./api/event/checkapi/", {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.api = response;
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't get api info atm");
      }
    );
  }

}
