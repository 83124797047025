
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { isTemplateSpan } from 'typescript';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  public corsKey: string = environment.corsKey;
  public tenantId: string = environment.tenantId;
  public corsUrl: string = environment.corsUrl;

  public localCart: any;
  public membership: any;

  public canCancel: boolean = false;
  public canRebook: boolean = true;

  public pendingRebooks: any[];

  constructor(public toastr: ToastrService, private modalService: NgbModal, public http: HttpClient, private sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router) {
    //this.getLocalCart();
    //this.getMembership();
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  addToPending(rebookObj) {
    if (!this.pendingRebooks) {
      this.pendingRebooks = [];
    }
    let updated = false;
    for (let i = 0; i < this.pendingRebooks.length; i++) {
      if (this.pendingRebooks[i].eventId == rebookObj.eventId) {
        this.pendingRebooks[i] = rebookObj;
        updated = true;
      }
    }
    if (!updated) {
      this.pendingRebooks.push(rebookObj);
    }
  }

  getPendingRebook(eventId) {
    if (!this.pendingRebooks) return null;
    if (!eventId) return null;

    for (let i = 0; i < this.pendingRebooks.length; i++) {
      if (this.pendingRebooks[i].eventId == eventId) {
        return this.pendingRebooks[i];
      }
    }
    return null;
  }

  async getMessage(id, type, success, fail) {
    return this.http.get("./api/message/message?id=" + id + "&type=" + type, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    }).subscribe(success, fail)/*.subscribe(response => {
    let message = (<any>response).data;
    return message;
  },
    err => {
      console.log(err);
      return null;
    }
  );*/
  }

  showNotification(type, msg) {
    let timeout = 7000;
    if (type == "success") {
      this.toastr.success(msg, 'Success', { timeOut: timeout, closeButton: true });
    } else if (type == "danger") {
      this.toastr.error(msg, 'Alert', { timeOut: timeout, closeButton: true });
    } else if (type == "warning") {
      this.toastr.warning(msg, 'warning', { timeOut: timeout, closeButton: true });
    } else {
      this.toastr.info(msg, 'info', { timeOut: timeout, closeButton: true });
    }
  }

  getUnSanatizedMessage(message) {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  isStandardCard(type: string) {
    let acceptedTypes = ['sr.individual', 'individual', 'Sr.family', 'family', 'temporary'];

    if (acceptedTypes.indexOf(type.toLowerCase()) > -1) {
      return true;
    }

    return false;
  }

  compareEventsByDisplayOrder(a, b) {
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    return 0;
  }

  formatTime(time) {
    if (time == null || time.trim() == "") {
      return "-";
    }
    let timeValues = time.split(':');
    var hours = timeValues[0];
    let minutes = timeValues[1];
    let suffix = (hours >= 12) ? 'PM' : 'AM';

    //only -12 from hours if it is greater than 12 (if not back at mid night)
    hours = (hours > 12) ? hours - 12 : hours;

    //if 00 then it is 12 am
    hours = (hours == '00') ? 12 : hours;

    return hours + ':' + minutes + ' ' + suffix;
  }


  getLocalCart() {
    if (!this.localCart) {
      this.localCart = JSON.parse(localStorage.getItem("ShoppingCartObject"));
      if (!this.localCart) {
        this.createCart();
      } else {
        // if(!createDate) localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(Date.now()));
        let createDate = JSON.parse(localStorage.getItem("ShoppingCartObjectUpdateDate"));
        if (!createDate) {
          this.createCart();
        } else {
          const diff = Date.now() - createDate;
          console.log(`seconds elapsed = ${Math.floor(diff / 1000)}`);
          if (diff >= 3600000) this.createCart();
        }
      }
    }
    return this.localCart;
  }

  getMembership() {

    let createDate = JSON.parse(localStorage.getItem("MembershipObjectUpdateDate"));
    if (createDate) {
      const diff = Date.now() - createDate;
      console.log(`seconds elapsed membership = ${Math.floor(diff / 1000)}`);
      if (diff >= 7200000) {
        localStorage.removeItem("MembershipObjectUpdateDate");
        localStorage.removeItem("MembershipObject");
        this.membership = null;
      }
    }

    if (!this.membership) {
      this.membership = JSON.parse(localStorage.getItem("MembershipObject"));
    }
    return this.membership;
  }

  setMembership(membershipCategory, items) {


    let membership = {
      member: items,
      cat: membershipCategory
    }

    console.log('membership cat');
    console.log(membershipCategory);
    console.log('membership items');
    console.log(items);

    let date = Date.now();
    localStorage.setItem("MembershipObjectUpdateDate", JSON.stringify(date));
    localStorage.setItem("MembershipObject", JSON.stringify(membership));
    this.membership = this.getMembership();
    return this.membership;
  }

  createCart() {
    this.localCart = {
      id: "",
      couponCodes: [],
      shoppingCartId: "",
      items: []
    };
    localStorage.setItem("ShoppingCartObject", JSON.stringify(this.localCart));
    let date = Date.now();
    localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(date));
  }

  getCartTotalItems() {
    let localCart = this.getLocalCart();
    let total = 0;
    localCart.items.forEach(element => {
      total += element.items.length;
    });
    return total;
  }

  updateLocalCartId(Id) {
    this.localCart.id = Id;
    this.localCart.shoppingCartId = Id;
    localStorage.setItem("ShoppingCartObject", JSON.stringify(this.localCart));
    localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(Date.now()));
  }

  updateLocalCart(CartItem) {

    let exists = false;
    this.localCart.items.forEach(element => {
      if (element.eventId == CartItem.eventId) {
        exists = true;
        CartItem.items.forEach(elementItem => {
          element.items.push(elementItem);
        });
        if (CartItem.forms) {
          if (element.forms) {
            CartItem.forms.responseForms.forEach(elementFrom => {
              element.forms.responseForms.push(elementFrom);
            });
          } else {
            element.forms = CartItem.forms;
          }
        }
      }
    });

    if (!exists) {
      this.localCart.items.push(CartItem);
    }
    localStorage.setItem("ShoppingCartObject", JSON.stringify(this.localCart));
    localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(Date.now()));
  }


  updateCartWithACMECartInfo(shoppingCart) {
    if (shoppingCart && shoppingCart.items) {
      if (shoppingCart.couponCodes[0] == '') {
        this.localCart.couponCodes = [];
      } else {
        this.localCart.couponCodes = shoppingCart.couponCodes;
      }

      shoppingCart.items.forEach(item => {
        this.localCart.items.forEach(eventObj => {
          if (item.eventId == eventObj.eventId) {
            let total = 0;
            eventObj.items.forEach(localItem => {
              total = localItem.amountDouble * localItem.quantity;
              if (item.ticketingTypeId == localItem.ticketingTypeId) {
                localItem.amount = item.amount;
                localItem.amountDouble = Number.parseFloat(item.amount);
                localItem.unitPrice = item.unitPrice;
                localItem.unitPriceDouble = Number.parseFloat(item.unitPrice);
                total = localItem.amountDouble * localItem.quantity;
              }
            });
            eventObj.total = total;
          }
        });
      });
      localStorage.setItem("ShoppingCartObject", JSON.stringify(this.localCart));
      localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(Date.now()));
    }
  }


  removeLocalCart(item) {
    this.localCart.items = this.arrayRemove(this.localCart.items, item)
    localStorage.setItem("ShoppingCartObject", JSON.stringify(this.localCart));
    localStorage.setItem("ShoppingCartObjectUpdateDate", JSON.stringify(Date.now()));
  }

  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  logout() {
    localStorage.removeItem("MembershipObjectUpdateDate");
    localStorage.removeItem("MembershipObject");
    this.membership = null;
    this.createCart();
    this.router.navigate([""]);
  }


}
