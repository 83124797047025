import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule, ToastrService, Overlay, OverlayContainer } from 'ngx-toastr';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { OrdersComponent } from './orders/orders.component';
import { CheckApiComponent } from './check-api/check-api.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { UtilityService } from './Services/utility.service';
import { MatDatepickerModule, MatNativeDateModule, MatInputModule, MatButtonModule, MatCardModule, MatCalendar } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { MembershipComponent } from './membership/membership.component';
import { RebookComponent } from './rebook/rebook.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    OrdersComponent,
    CheckApiComponent,
    LoginComponent,
    MembershipComponent,
    RebookComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModule,
    RecaptchaV3Module,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    ToastrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterModule.forRoot([
      { path: '', component: RebookComponent, pathMatch: 'full' },
      { path: 'orders', component: OrdersComponent },
      { path: 'orders/:id', component: OrdersComponent },
      { path: 'membership', component: MembershipComponent },
      { path: 'membership/:id', component: MembershipComponent },
      { path: 'rebook', component: RebookComponent },
    ])
  ],
  providers: [OverlayContainer, Overlay, ToastrService, UtilityService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captcha }],
  bootstrap: [AppComponent]
})
export class AppModule { }
