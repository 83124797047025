import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityService } from '../Services/utility.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  loading = false;
  data: any = null;
  email: string = "";
  lastName: string = "";
  selectedOrder: any = null;
  constructor(public http: HttpClient, public utilityService: UtilityService, private $gaService: GoogleAnalyticsService) {
  }

  ngOnInit() {
    this.$gaService.pageView('/Order', 'Order Lookup');
  }

  reset() {
    this.data = null;
    this.email = "";
    this.lastName = "";
  }

  resend(orderNumber: string) {
    this.loading = true;
    this.http.post("./api/order/resendEmail/" + orderNumber + "?email=" + this.email, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.utilityService.showNotification("success", "Email Sent");
      this.$gaService.event('resend_email','order_lookup','Resend Confirmation Email');
      
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger", "Couldn't send confirmation at this moment");
        this.$gaService.event('resend_email','order_lookup','Resend confirmation Email Error');
      }
    );
  }

  cancelOrder(modal: any){
    this.loading = true;
    this.http.post("./api/order/cancelOrder/" + this.selectedOrder.orderId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      modal.close();
      this.utilityService.showNotification("success", "Order Cancelled");
      this.checkOrders();
      this.$gaService.event('cancel_order','order_lookup','Cancel Order');
    },
      err => {
        this.loading = false;
        modal.close();
        console.log(err);
        this.utilityService.showNotification("danger", err.data);
        this.$gaService.event('cancel_order','order_lookup','Cancel Order Error');
      }
    );
  }

  canCancel(order:any){
    var canCacel = false;
    order.orderItems.forEach(orderItem => {
      orderItem.lineItems.forEach(item => {
        if(item.orderItemQuantity > 0){
          canCacel =  true;
        }
      });
    });

    return canCacel;
  }

  //checkOrders(orderForm: NgForm) {
  checkOrders() {
    this.loading = true;
    this.http.post("./api/order/orders?email=" + this.email + "&lastname=" + this.lastName, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    }).subscribe(response => {
      this.loading = false;
      this.data = (<any>response).data;
      console.log(this.data);
      this.$gaService.event('order_lookup','order_lookup',this.lastName);
    },
      err => {
        this.loading = false;
        console.log(err);
        this.utilityService.showNotification("danger",'could not load orders, please try again later')
        //alert('could not load orders, please try again later');
      }
    );
  }
}